import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { PanelHeader, Spinner } from '../../components';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import { events, visits } from '../../helpers/actions/pros';
import { clientName } from '../../helpers/formatters';
import EventModal from './EventModal';
import VisitModal from './VisitModal';

moment.locale('fr');
const localizer = BigCalendar.momentLocalizer(moment);

const Calendar = translate('translations-fr')(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        visits: [],
        events: [],
        selectedEvent: null,
        selectedVisit: null
      };
      this.hideAlert = this.hideAlert.bind(this);
    }

    load(date) {
      this.setState({ loading: true });
      let { dispatch } = this.props;

      let dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
      let dateTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      Promise.all([
        dispatch(visits({ dateFrom, dateTo })),
        dispatch(events({ dateFrom, dateTo }))
      ])
        .then(([visits, events]) => {
          visits = this.processVisits(visits.data);
          events = this.processEvents(events.data);
          this.setState({ visits, events, loading: false });
        })
        .catch(err => {
          this.setState(ps => ({ ...ps, loading: false }));
        });
    }

    processVisits(visits) {
      return visits.map(visit => {
        let client = visit.client.length
          ? clientName(visit.client[0]) + ' - '
          : '';
        return {
          type: 'visit',
          title: `${client}${visit.project.name}`,
          allDay: false,
          start: new Date(visit.date),
          end: new Date(visit.date),
          color: 'azure',
          client: visit.client.length ? visit.client[0] : {},
          supervisor: visit.supervisor ? visit.supervisor : {},
          project: visit.project,
          text: visit.comments_from_pro + '<br/>' + visit.comments_from_client
        };
      });
    }

    processEvents(events) {
      return events.map(event => {
        let client =
          event.client && event.client.length
            ? clientName(event.client[0]) + ' - '
            : '';
        return {
          type: 'event',
          title: `${client}${event.title}`,
          text: event.text,
          allDay: false,
          start: new Date(event.start),
          end: new Date(event.end),
          color: 'green',
          project: event.project
        };
      });
    }

    componentWillMount() {
      this.load(new Date());
    }

    hideAlert() {
      this.setState({
        selectedEvent: null,
        selectedVisit: null
      });
    }

    selectedItem(item) {
      switch (item.type) {
        case 'visit':
          this.setState({ selectedEvent: null, selectedVisit: item });
          break;
        case 'event':
          this.setState({ selectedEvent: item, selectedVisit: null });
          break;
        default:
          this.setState({ selectedEvent: null, selectedVisit: null });
          break;
      }
    }

    eventColors(event) {
      let backgroundColor = 'event-';
      event.color
        ? (backgroundColor = backgroundColor + event.color)
        : (backgroundColor = backgroundColor + 'default');
      return {
        className: backgroundColor
      };
    }

    render() {
      let { loading, selectedEvent, selectedVisit } = this.state;
      let { t } = this.props;

      return (
        <div>
          <PanelHeader size="sm" />
          <div className="content">
            {loading ? <Spinner /> : null}
            <Card className="card-calendar">
              <CardBody>
                <BigCalendar
                  messages={{
                    date: t('date'),
                    time: t('time'),
                    event: t('event'),
                    allDay: t('All Day'),
                    week: t('week'),
                    day: t('day'),
                    month: t('month'),
                    previous: t('previous'),
                    next: t('next'),
                    yesterday: t('yesterday'),
                    tomorrow: t('tomorrow'),
                    today: t('today'),
                    agenda: t('agenda')
                  }}
                  localizer={localizer}
                  selectable
                  events={this.state.events.concat(this.state.visits)}
                  defaultView="month"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={item => this.selectedItem(item)}
                  eventPropGetter={this.eventColors}
                  onNavigate={date => this.load(date)}
                />
              </CardBody>
            </Card>

            {selectedVisit ? (
              <VisitModal
                hide={() => this.hideAlert()}
                selectedVisit={selectedVisit}
              />
            ) : null}
            {selectedEvent ? (
              <EventModal
                hide={() => this.hideAlert()}
                selectedEvent={selectedEvent}
              />
            ) : null}
          </div>
        </div>
      );
    }
  }
);

export default connect((state, ownProps) => ownProps)(Calendar);
